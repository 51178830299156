import * as React from "react";
import Header from "../components/Header";
import { LogoScroller } from "../components/Logos";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import { GetInTouch } from "../components/ActionButtons";
import { graphql, useStaticQuery } from "gatsby";
import Link from "../components/Link";
import { Helmet } from "react-helmet";
import ArticleRowsHome from "../layouts/ArticleRowsHome";


const IndexPage = () => {
  const logoData = useStaticQuery(graphql`
    query artistsQueryAndFeaturesQuery {
      allLogosYaml(filter: { type: { eq: "Artist" } }) {
        totalCount
        edges {
          node {
            id
            name
            link
            logo {
              childImageSharp {
                gatsbyImageData(
                  width: 128
                  height: 55
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: CONTAIN }
                  placeholder: NONE
                  backgroundColor: "transparent"
                )
              }
            }
          }
        }
      }
      allMdx(
        filter: { fileAbsolutePath: { regex: "/content/home/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            body
            id
            frontmatter {
              title
              description
              anchor
              image {
                childImageSharp {
                  gatsbyImageData(
                    height: 300
                    formats: [AUTO, WEBP]
                    placeholder: BLURRED
                    backgroundColor: "transparent"
                  )
                }
              }
            }
          }
        }
      }
    }
  `);


  return (
    <div>
    <div className="bg-mg-purple min-h-screen w-screen overflow-x-hidden">
      <Helmet>
        <meta name="google-site-verification" content="zbsDWQRcAwW1PlpU9VQC_7mgYRehLquXJymSDBXqulk" />
      </Helmet>
      <Seo />
      <Header />
      <main className="text-white flex flex-col justify-between min-h-screen relative inset-0 pt-24">
        <StaticImage
          className="inset-0 z-0 opacity-30"
          src="../assets/img/backgrounds/artist-grid.jpeg"
          alt=""
          imgStyle={{ position: "fixed", minHeight: "100vh" }}
          style={{ position: "fixed" }}
          layout="fullWidth"
        />
        <div className="m-auto pb-6 px-4 pt-4 md:pt-2 max-w-xxl z-1 relative text-left">
          <div className="grow-0 max-w-prose flex flex-col justify-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-5 sm:mb-2">The ultimate <span className="text-mg-yellow">solution</span> for artists’ <span className="text-mg-red">ticketing</span></h1>
            <h2 className="ml-1 mb-6 text-mg-light font-medium pr-2 block text-base lg:text-2xl max-w-prose">
                <span className="text-white">Sell</span> tickets directly to your fans, with <span className="text-white">low</span> booking fees, and <span className="text-white">own</span> the data.
            </h2>
            <p>
              <Link to="/contact" className="btn hover:bg-white hover:text-mg-purple">
                Get In Touch
              </Link>
            </p>
          </div>
        </div>
        <div className="mb-24 md:mb-8">
          <LogoScroller
            data={logoData.allLogosYaml.edges}
            count={logoData.allLogosYaml.totalCount}
          />
        </div>
      </main>
    </div>
    <ArticleRowsHome
      title="Home"
      description="The ultimate solution for artists’ ticketing"
      data={logoData.allMdx.edges}
    />
    
  </div>
  );
};

export default IndexPage;
