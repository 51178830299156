import * as React from "react";
import Header from "../components/Header";
import Seo from "../components/Seo";
import { MDX, MDXProps } from "../components/Mdx";
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";
import { GetInTouch } from "../components/ActionButtons";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import { useEffect } from "react";
import gsap from "gsap";
import { LogoGrid } from "../components/Logos";

type MDXNochild = Omit<MDXProps, "children">;

export interface ArticleLayout extends MDXNochild {
  data?: Array<{ node: ArticleProps }>;
  title: string;
  description: string;
}

export interface ArticleProps {
  id: React.Key;
  anchor?: string;
  slug?: string;
  image?: IGatsbyImageData;
  title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
  frontmatter: MDXProps["frontmatter"] & { slug?: string };
  body: string;
}

const ArticleRowsHome = ({ data, title, description }: ArticleLayout) => {
  const articles =
    data?.map(({ node }) => {
      const { body, id, frontmatter } = node;
      const title = frontmatter!.title;
      const anchor = frontmatter!.anchor;
      const image = getImage(frontmatter!.image);

      return { id, title, body, frontmatter, image, anchor };
    }) ?? [];

  useEffect(() => {
    const animatedUp = gsap.utils.toArray(".AnimatedUp") as HTMLElement[];

    animatedUp.forEach((el) => {
      gsap.from(el, {
        duration: 1,
        opacity: 0,
        y: 100,
        ease: "power3.out",
        scrollTrigger: {
          trigger: el,
        },
      });
    });
  }, []);

  return (
    <>
      <Seo title={title} description={description} />
      <Header />
      <div className="flex flex-col min-h-screen">
        <main className="grow flex flex-col">
          {articles.map((a: ArticleProps, i: number) => {
            return (
              <article key={a.id} className={`${i % 2 != 0 ? "bg-gray-100" : "bg-white"} relative`}>
                <span id={a.anchor} className="absolute -top-14"></span>
                <div
                  className={`container max-w-6xl mx-auto px-2 py-10 sm:py-16 flex flex-col-reverse items-center justify-between ${
                    i % 2 == 0 ? "md:flex-row-reverse" : "md:flex-row"
                  }`}
                >
                  {a.image ? (
                    <div className="basis-1/2 max-w-md p-4 mx-auto flex items-center justify-center AnimatedUp">
                      <GatsbyImage image={a.image} alt="" />
                    </div>
                  ) : a.frontmatter!.logos ? (
                    <div className="basis-1/2 p-6 mx-auto flex items-center justify-center ">
                      <LogoGrid
                        logos={a.frontmatter!.logos.slice(0, 12)}
                        logoClass="invert AnimatedUp"
                        nolinks
                      />
                    </div>
                  ) : null}

                  <div className="basis-1/2 p-4">
                    <h2 className="text-3xl mb-4">{a.title}</h2>
                    <p className="font-bold">{a.frontmatter!.description}</p>
                    <MDX frontmatter={a.frontmatter}>{a.body}</MDX>
                    <p className="mt-7">
                      <GetInTouch />
                    </p>
                  </div>
                </div>
              </article>
            );
          })}
          <div className="basis-full px-8 py-16 text-center bg-white	relative">
            <h2 className="text-3xl mb-4">We build bespoke e-commerce for the music industry</h2>
            <p className="mt-7">
              <a className="btn" href="/features">See all features</a>
            </p>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ArticleRowsHome;
